import React from 'react';

interface IColors {
  color: string;
}

const BgPrize = ({ color }: IColors) => {
  return (
    <svg
      width="112.000000"
      height="112.000000"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {/* золотой */}
      <linearGradient
        id="line-gold-0"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFBB27" />
        <stop offset="1.000000" stopColor="#0C1027" stopOpacity="0.000000" />
      </linearGradient>
      <radialGradient
        id="line-gold-1"
        cx="0.000000"
        cy="0.000000"
        r="1.000000"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56 33.3846) rotate(90) scale(114.692 72.7317)"
      >
        <stop stopColor="#DFBB27" />
        <stop offset="1.000000" stopColor="#5F4F12" stopOpacity="0.000000" />
      </radialGradient>
      <linearGradient
        id="line-gold-2"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C1027" stopOpacity="0.000000" />
        <stop offset="1.000000" stopColor="#DFBB27" />
      </linearGradient>
      {/* красный */}
      <linearGradient
        id="line-red-0"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EC433C" />
        <stop offset="1.000000" stopColor="#0C1027" stopOpacity="0.000000" />
      </linearGradient>
      <radialGradient
        id="line-red-1"
        cx="0.000000"
        cy="0.000000"
        r="1.000000"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56 33.3846) rotate(90) scale(114.692 72.7317)"
      >
        <stop stopColor="#EC433C" />
        <stop offset="1.000000" stopColor="#762C28" stopOpacity="0.000000" />
      </radialGradient>
      <linearGradient
        id="line-red-2"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C1027" stopOpacity="0.000000" />
        <stop offset="1.000000" stopColor="#EC433C" />
      </linearGradient>
      {/* розовый */}
      <linearGradient
        id="line-pink-0"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DA47FD" />
        <stop offset="1.000000" stopColor="#0C1027" stopOpacity="0.000000" />
      </linearGradient>
      <radialGradient
        id="line-pink-1"
        cx="0.000000"
        cy="0.000000"
        r="1.000000"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56 33.3846) rotate(90) scale(114.692 72.7317)"
      >
        <stop stopColor="#BE48DA" />
        <stop offset="1.000000" stopColor="#632472" stopOpacity="0.000000" />
      </radialGradient>
      <linearGradient
        id="line-pink-2"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C1027" stopOpacity="0.000000" />
        <stop offset="1.000000" stopColor="#DA47FD" />
      </linearGradient>
      {/* фиолетовый */}
      <linearGradient
        id="line-purple-0"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E30FA" />
        <stop offset="1.000000" stopColor="#0C1027" stopOpacity="0.000000" />
      </linearGradient>
      <radialGradient
        id="line-purple-1"
        cx="0.000000"
        cy="0.000000"
        r="1.000000"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56 33.3846) rotate(90) scale(114.692 72.7317)"
      >
        <stop stopColor="#824EF6" />
        <stop offset="1.000000" stopColor="#37206C" stopOpacity="0.000000" />
      </radialGradient>
      <linearGradient
        id="line-purple-2"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C1027" stopOpacity="0.000000" />
        <stop offset="1.000000" stopColor="#6E30FA" />
      </linearGradient>
      {/* синий */}
      <linearGradient
        id="line-blue-0"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3C60FC" />
        <stop offset="1.000000" stopColor="#0C1027" stopOpacity="0.000000" />
      </linearGradient>
      <radialGradient
        id="line-blue-1"
        cx="0.000000"
        cy="0.000000"
        r="1.000000"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56 33.3846) rotate(90) scale(114.692 72.7317)"
      >
        <stop stopColor="#3C60FC" />
        <stop offset="1.000000" stopColor="#1B2862" stopOpacity="0.000000" />
      </radialGradient>
      <linearGradient
        id="line-blue-2"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C1027" stopOpacity="0.000000" />
        <stop offset="1.000000" stopColor="#3C60FC" />
      </linearGradient>
      {/* светло-синий */}
      <linearGradient
        id="line-light-blue-0"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5C98EF" />
        <stop offset="1.000000" stopColor="#0C1027" stopOpacity="0.000000" />
      </linearGradient>
      <radialGradient
        id="line-light-blue-1"
        cx="0.000000"
        cy="0.000000"
        r="1.000000"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56 33.3846) rotate(90) scale(114.692 72.7317)"
      >
        <stop stopColor="#5C98EF" />
        <stop offset="1.000000" stopColor="#254472" stopOpacity="0.000000" />
      </radialGradient>
      <linearGradient
        id="line-light-blue-2"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C1027" stopOpacity="0.000000" />
        <stop offset="1.000000" stopColor="#5C98EF" />
      </linearGradient>
      {/* серый */}
      <linearGradient
        id="line-grey-0"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6F6F6F" />
        <stop offset="1.000000" stopColor="#0C1027" stopOpacity="0.000000" />
      </linearGradient>
      <radialGradient
        id="line-grey-1"
        cx="0.000000"
        cy="0.000000"
        r="1.000000"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56 33.3846) rotate(90) scale(114.692 72.7317)"
      >
        <stop stopColor="#949494" />
        <stop offset="1.000000" stopColor="#3C3C3C" stopOpacity="0.000000" />
      </radialGradient>
      <linearGradient
        id="line-grey-2"
        x1="55.999996"
        y1="0.000000"
        x2="55.999996"
        y2="112.000000"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C1027" stopOpacity="0.000000" />
        <stop offset="1.000000" stopColor="#6F6F6F" />
      </linearGradient>
      <path
        id="Vector (Stroke)"
        d="M40 0L72 0C74.1217 0 76.1566 0.842834 77.6569 2.34314L109.657 34.3431C111.157 35.8434 112 37.8783 112 40L112 72L96 72L96 43.3137L68.6863 16L40 16L40 0ZM16 68.6863L16 40L0 40L0 72C0 74.1217 0.842857 76.1566 2.34315 77.6569L34.3431 109.657C35.8434 111.157 37.8783 112 40 112L72 112L72 96L43.3137 96L16 68.6863Z"
        fill={
          color === '#DFBB27'
            ? 'url(#line-gold-0)'
            : color === '#EC433C'
              ? 'url(#line-red-0)'
              : color === '#BE48DA'
                ? 'url(#line-pink-0)'
                : color === '#6E30FA'
                  ? 'url(#line-purple-0)'
                  : color === '#3C60FC'
                    ? 'url(#line-blue-0)'
                    : color === '#5C98EF'
                      ? 'url(#line-light-blue-0)'
                      : color === '#6F6F6F'
                        ? 'url(#line-grey-0)'
                        : ''
        }
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector (Stroke)"
        d="M40 0L72 0C74.1217 0 76.1566 0.842834 77.6569 2.34314L109.657 34.3431C111.157 35.8434 112 37.8783 112 40L112 72L96 72L96 43.3137L68.6863 16L40 16L40 0ZM16 68.6863L16 40L0 40L0 72C0 74.1217 0.842857 76.1566 2.34315 77.6569L34.3431 109.657C35.8434 111.157 37.8783 112 40 112L72 112L72 96L43.3137 96L16 68.6863Z"
        fill={
          color === '#DFBB27'
            ? 'url(#line-gold-1)'
            : color === '#EC433C'
              ? 'url(#line-red-1)'
              : color === '#BE48DA'
                ? 'url(#line-pink-1)'
                : color === '#6E30FA'
                  ? 'url(#line-purple-1)'
                  : color === '#3C60FC'
                    ? 'url(#line-blue-1)'
                    : color === '#5C98EF'
                      ? 'url(#line-light-blue-1)'
                      : color === '#6F6F6F'
                        ? 'url(#line-grey-1)'
                        : ''
        }
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector (Stroke)"
        d="M96 43.3137L96 72L112 72L112 40C112 37.8783 111.157 35.8434 109.657 34.3431L77.6569 2.34314C76.1566 0.842834 74.1217 0 72 0L40 0L40 16L41 16L68.6863 16L96 43.3137ZM41 15L69.1005 15L97 42.8995L97 71L111 71L111 40Q111 38.5502 110.487 37.3127Q109.975 36.0753 108.95 35.0502L76.9497 3.05023Q75.9246 2.02515 74.6872 1.51257Q73.4497 1 72 1L41 1L41 15ZM16 68.6863L16 41L16 40L0 40L0 72C0 74.1217 0.842857 76.1566 2.34315 77.6569L34.3431 109.657C35.8434 111.157 37.8783 112 40 112L71 112L72 112L72 111L72 97L72 96L71 96L43.3137 96L16 68.6863ZM71 97L42.8995 97L15 69.1005L15 41L1 41L1 72Q1 73.4497 1.51256 74.6871Q2.02512 75.9246 3.05025 76.9498L35.0502 108.95Q36.0754 109.975 37.3128 110.487Q38.5503 111 40 111L71 111L71 97Z"
        fill={
          color === '#DFBB27'
            ? 'url(#line-gold-2)'
            : color === '#EC433C'
              ? 'url(#line-red-2)'
              : color === '#BE48DA'
                ? 'url(#line-pink-2)'
                : color === '#6E30FA'
                  ? 'url(#line-purple-2)'
                  : color === '#3C60FC'
                    ? 'url(#line-blue-2)'
                    : color === '#5C98EF'
                      ? 'url(#line-light-blue-2)'
                      : color === '#6F6F6F'
                        ? 'url(#line-grey-2)'
                        : ''
        }
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default BgPrize;
