import React from 'react';
interface IColors {
  color: string;
}
const CircleRarity = ({ color }: IColors) => {
  return (
    <svg
      width="425.000000"
      height="425.000000"
      viewBox="0 0 425 425"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <filter
          id="filter_28_19429_dd"
          x="0.000000"
          y="0.000000"
          width="425.000000"
          height="425.000000"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="50" result="effect_layerBlur_1" />
        </filter>
      </defs>
      <g filter="url(#filter_28_19429_dd)">
        <circle
          id="light-bg"
          cx="212.500000"
          cy="212.500000"
          r="62.500000"
          fill={color}
          fillOpacity="0.400000"
        />
      </g>
    </svg>
  );
};

export default CircleRarity;
