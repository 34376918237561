import React from 'react';
import Image from 'next/image';
import LeftBorderPrice from '@/assets/icons/give/leftBorderPrice.svg';
import RightBorderPrice from '@/assets/icons/give/rightBorderPrice.svg';
import priceIcon from '@/assets/icons/give/priceIcon.svg';
import styles from './GivePrice.module.scss';
import HyperRub from '@/assets/icons/hyperRub';

interface IChallengeDropCard {
  price: number | null | undefined;
}

const PriceComponent = ({ price }: IChallengeDropCard) => {
  return (
    <div className={styles.main_price}>
      <Image src={LeftBorderPrice} alt="icon_price" />
      <div className={styles.container_price}>
        <Image src={priceIcon} alt="icon_price" />
        <p className={styles.price}>
          {price} <HyperRub />
        </p>
      </div>
      <Image src={RightBorderPrice} alt="icon_price" />
    </div>
  );
};

export default PriceComponent;
